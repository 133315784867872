import React from 'react'
import { ENGLISH_TITLE } from '../../components/data/English'
import { SEO } from '../../components/data/SEO'
import { sideNavDataCompany } from '../../components/data/sideNavData'
import { LayoutDefault } from '../../components/organisms/Layout/LayoutDefault'

const Page = () => {
  return (
    <LayoutDefault
      heading="組織図"
      headingEnglish={ENGLISH_TITLE.Organization}
      BreadcrumbData={[
        { title: '企業情報', url: '/company' },
        { title: '組織図', url: '' },
      ]}
      sideNavHeading="企業情報"
      sideNavList={sideNavDataCompany}
    >
      <section className="bg-white">
        <div className="pc:py-12 pc:px-8">
          <img src="/company/img/chart.png" alt="組織図" />
        </div>
      </section>
    </LayoutDefault>
  )
}

export default Page

export const Head = () => <SEO title="組織図" url="/company/chart" />
